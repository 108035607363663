<template>
  <div class="view-wrapper overflow-auto">
    <v-container>
      <v-row class="align-center pt-12 mt-12">
        <v-col cols="12" md="6">
          <div style="justify-content: center; text-align: center;" class="mb-12">
            <h1>ABC</h1><br><br>
            <h2>Инвестируйте в Действующий Бизнес Полностью цифровом формате</h2>
            <h3>Инвестируйте от 100 000 тенге и получайте доходность до 36% годовых. Только прибыльный проекты, прошедшие проверку и оценку инвестиционных рисков. </h3><br>
            <v-row v-if="isAuthenticated && !accExist" class="justify-center">
              <v-btn outlined
                     class="pa-5 ma-1"
                     color="success"
                     @click="$router.push('/auth/register').catch(() => {});"
              >
                {{ $localize('become_an_investor') }}
              </v-btn>
              <v-btn text
                     class="pa-5 ma-1"
                     color="success"
                     @click="accExist = true"
              >
                {{ $localize('already_have_an_account?') }}
              </v-btn>
            </v-row>
            <v-row v-if="isAuthenticated && accExist" justify="center">
              <v-btn outlined
                     class="pa-5 ma-1"
                     color="success"
                     @click="logAs('investor')"
              >
                {{ $localize('sing_in_like_investor') }}
              </v-btn>
              <v-btn outlined
                     class="pa-5 ma-1"
                     color="success"
                     @click="logAs('startup')"
              >
                {{ $localize('sing_in_like_startup') }}
              </v-btn>
              <v-btn outlined
                     class="pa-5 ma-1"
                     color="success"
                     @click="logAs('admin')"
              >
                {{ $localize('sing_in_like_admin') }}
              </v-btn>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-img src="https://utk.susu.ru/wp-content/uploads/2020/05/original-7b19aff6323caa32c2e280df91a5cfb0.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="align-center justify-center" style="height: 200px; background-color: #E8F5E9; width: 100vw">
      <div style="justify-content: center; text-align: center;" class="pa-8">
        <h2>Что такое ABC?</h2><br>
        <span class="title">Платформа для инвестиций, с которой инвестировать просто</span>
      </div>
    </v-row>
    <v-container>
      <v-row class="justify-center align-center" style="height: 200px;">
        <div style="justify-content: center; text-align: center;">
          <v-container>
            <span class="title">Мы даём возможность частным инвесторам (физическим и юридическим лицам) получать высокую доходность, а бизнесу привлечь средства полностью онлайн и без залога, через инвестирование в ТОО, либо договор займа.</span>
          </v-container>
        </div>
      </v-row>
    </v-container>
    <v-row class="align-center justify-center pb-6 pt-6" style="height: auto; background-color: #E8F5E9; width: 100vw">
      <div>
        <div style="justify-content: center; text-align: center;">
          <h2>Что мы предлагаем:</h2><br>
        </div>
        <v-container class="pt-0">
          <v-row>
            <v-col cols="12" md="6">
              <div class="pa-4">
                <h3>1. Доходность выше, чем по депозитным вкладам</h3><br>
                <span class="subtitle-1">Доходность до 36% годовых, благодаря отбору только прибыльных и перспективных проектов. Мы отбираем, вы инвестируете</span>
              </div>
              <div class="pa-4">
                <h3> 3. Инвестировать просто</h3><br>
                <span class="subtitle-1">Подписание документов и перевод средств удаленно, с помощью ЭЦП. Инвестируйте с нами онлайн</span>
              </div>
              <div class="pa-4">
                <h3>5. Клуб инвесторов AFIC</h3><br>
                <span class="subtitle-1">Инвестирующие от 5 млн тг вступают в клуб и получают доступ к проектам первыми. Члены клуба участвуют в оценке проектов</span>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="pa-4">
                <h3>2. Пополнение счета из любого банка</h3><br>
                <span class="subtitle-1">Теперь чтобы стать инвестором, не нужно иметь счет в определенном банке, а инвестором может стать физическое, юридическое лицо и индивидуальный предприниматель</span>
              </div>
              <div class="pa-4">
                <h3>4. Без скрытых комиссий</h3><br>
                <span class="subtitle-1">Мы не взимаем комиссий с инвестора. Вы сможете пополнить счет инвестпроекта переводом из любого банк</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  data : () => ({
    accExist: false
  }),

  computed:{
    isAuthenticated(){
      return !this.$cookies.user
    }
  },

  methods:{
    logAs(role){
      switch (role){
        case 'startup' : this.$router.push('/startup/auth/login')
          break;
        case 'investor' : this.$router.push('/investor/auth/login')
          break;
        case 'admin' : this.$router.push('/admin/auth/login')
          break;
      }
    }
  }
}
</script>
